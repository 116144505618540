<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 lg12 xl12>
          <app-title :title="'Agregue los productos'" :subtitle="'Solo los que desea vender en su zona de reparto'">
            <template slot="button">
              <v-badge color="accent" left overlap>
                <template v-slot:badge>
                  <span>{{ cart.length }}</span>
                </template>
                <v-icon large color="grey lighten-1" @click="openCart">store</v-icon>
              </v-badge>
            </template>
          </app-title>
          <template v-if="showCart && cart.length > 0">
            <v-data-table :headers="headers" :items="cart" class="elevation-2 mt-3" hide-actions>
              <template slot="items" slot-scope="props">
                <td>
                  <v-layout align-center justify-center row>
                    <v-flex xs2>
                      {{ props.item.id }}
                      <v-avatar :tile="true" :size="42" color="grey lighten-4">
                        <img :src="props.item.img.url_small" alt="avatar" />
                      </v-avatar>
                    </v-flex>
                    <v-flex class="text-xs-left" xs10>
                      <h2 class="body-2" v-html="`${props.item.name} (P${props.item.id})`"></h2>
                    </v-flex>
                  </v-layout>
                </td>
                <td class="text-xs-right">
                  {{ props.item.price | toPrice }}
                </td>
                <td class="text-xs-right">
                  <v-btn color="error" flat icon @click="props.item.selected = !props.item.selected">
                    <v-icon> clear </v-icon>
                  </v-btn>
                </td>
              </template>
              <template slot="footer">
                <tr>
                  <td :colspan="2" class="text-xs-right">
                    <strong>Total de productos</strong>
                  </td>
                  <td :colspan="1" class="text-xs-right">
                    {{ cart.length }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-btn
              v-if="!success"
              :loading="btnSend"
              :disabled="btnSend"
              color="accent"
              class="mx-0 mt-3 mb-0"
              block
              @click="addProductStore"
            >
              Añadir a su tienda
            </v-btn>
            <v-alert v-else :value="true" type="success">
              Todos los productos añadidos a su tienda (Se encuentra inactivos y con un precio por defecto) para
              modificarlos ingrese a su
              <router-link
                class="font-weight-bold white--text"
                :to="{ name: 'products' }"
                style="text-decoration:none;"
              >
                listado de productos
              </router-link>
            </v-alert>
          </template>
        </v-flex>
        <v-flex v-if="!success" xs12 lg4 xl4>
          <v-combobox
            v-model="categories"
            :items="categoriesAll"
            item-text="name"
            item-value="id"
            label="Categorias"
            hide-details
            multiple
            chips
            clearable
            solo
            small-chips
          >
          </v-combobox>
        </v-flex>
        <v-flex v-if="!success" xs12 lg8 xl8>
          <v-text-field
            v-model="searchText"
            hide-details
            append-icon="search"
            placeholder="Buscar producto por nombre, codigo, etc."
            solo
            single-line
            right
            clearable
            @click:clear="handleClearableSearch"
            @keyup.native="searchInputHandle"
          ></v-text-field>
        </v-flex>
        <template v-for="(product, index) in products">
          <v-flex v-if="!success" :key="index" xs12 sm4 md3 lg3 xl2>
            <v-card>
              <app-title-card :title="product.name" :subtitle="`P${product.id}`"></app-title-card>
              <v-divider></v-divider>
              <v-img :src="product.img.url_small" :lazy-src="`https://picsum.photos/10/6`" class="lighten-2" />
              <v-chip color="info" class="white--text" style="position:absolute;bottom:5px; left: 5px" label>
                {{ product.price | toPrice }}
              </v-chip>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!product.selected" flat color="accent" @click="addProduct(index)"> Añadir </v-btn>
                <v-btn v-else flat color="error" @click.native="addProduct(index)"> Quitar </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </template>
        <template v-if="products.length === 0 && !success">
          <v-flex xs12 lg12 xl12 class="text-xs-center py-4">
            <h4 class="grey--text font-weight-regular">Realice una busqueda para listar los productos</h4>
          </v-flex>
        </template>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import qs from 'qs'
import { GET_API, GET_PRODUCT_ADD } from '../../config'
import appTitle from '../useful/title.vue'
import appTitleCard from '../useful/titleCard.vue'

export default {
  name: 'ProductAddStore',
  components: { appTitle, appTitleCard },
  data: () => ({
    categories: [],
    categoriesAll: [],
    selecteds: [],
    products: [],
    productsAll: [],
    tags: [],
    btnSend: false,
    success: false,
    headers: [
      {
        text: 'Nombre',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Prefio por defecto',
        align: 'right',
        sortable: true,
        value: 'value'
      },
      {
        text: '',
        align: 'right',
        sortable: false,
        value: 'action'
      }
    ],
    product: {
      name: null,
      url: null,
      description: null,
      price: null
    },
    searchText: '',
    showCart: false
  }),
  computed: {
    cart() {
      return this._.filter(this.productsAll, ['selected', true])
    },
    massive() {
      return this.$store.getters.getMassive
    }
  },
  watch: {
    categories(e) {
      if (e.length > 0) {
        const categories = []
        e.forEach(el => {
          categories.push(el.id)
        })
        const products = this._.filter(this.productsAll, item => {
          const int = this._.intersection(item.categoriesIds, categories)
          return int.length > 0
        })
        this.products = products
      } else {
        this.products = []
      }
    },
    massive(e) {
      if (e.send === 1) {
        setTimeout(() => {
          if (e.key !== undefined) {
            this.addProductShop(e.key)
          } else {
            this.btnSend = false
            this.success = true
          }
        }, 2000)
      }
    }
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    async getProducts() {
      const res = await this.$http.get(GET_PRODUCT_ADD)
      this.productsAll = res.data
      this.products = []
      const filterOrder = []
      this.productsAll.forEach((el, index) => {
        this.productsAll[index].categoriesIds = []
        el.categories.forEach(category => {
          filterOrder.push({
            id: category.id,
            name: category.name
          })
          this.productsAll[index].categoriesIds.push(category.id)
        })
      })
      this.categoriesAll = this._.uniqBy(filterOrder, 'id')
      if (this.categories.length > 0) {
        const categories = []
        this.categories.forEach(el => {
          categories.push(el.id)
        })
        const products = this._.filter(this.productsAll, item => {
          const int = this._.intersection(item.categoriesIds, categories)
          return int.length > 0
        })
        this.products = products
      }
      this.menu = false
    },
    async addProductShop(e) {
      await this.$http.post(GET_PRODUCT_ADD, {
        product: this.selecteds[e].id
      })
      this.selecteds[e].send = 2
      this.sendFirst()
    },
    openCart() {
      this.showCart = !this.showCart
    },
    addProduct(index) {
      this.products[index].selected = !this.products[index].selected
    },
    searchInputHandle() {
      const search = this.searchText
      const products = this.productsAll
      if (search.length > 3) {
        let result = this._.filter(products, item =>
          this._.includes(this._.lowerCase(item.name), this._.lowerCase(search))
        )
        if (result.length === 0) {
          result = this._.filter(products, item => this._.includes(this._.lowerCase(item.id), this._.lowerCase(search)))
        }
        if (result.length > 0 && search !== '') {
          this.products = result
        } else {
          this.products = []
        }
        this.dialogSearch = false
      } else {
        this.products = []
      }
    },
    handleClearableSearch() {
      console.log('clear')
    },
    addProductStore() {
      this.btnSend = true
      this.selecteds = this.cart
      this._.forEach(this.selecteds, (item, index) => {
        this.selecteds[index].send = 1
      })
      this.sendFirst()
    },
    sendFirst() {
      const key = this._.findKey(this.selecteds, { send: 1 })
      this.$store.dispatch('changeMassive', { key, send: 1 })
    }
  }
}
</script>
